@import "node_modules/reset-css/sass/_reset.scss";
@import './_variables.scss';
@import "./assets/styles/colors";
@import "./assets/styles/theme";

h1, h2, h3, h4, h5 {
  color: var(--text-color);
  font-family: $font-family-2;
  line-height: 2.2rem;
  text-shadow: 3px 3px rgb(61, 173, 155);
}

p, span, table, button {
  color: var(--text-color);
  font-family: $font-family-3;
}

a {
  font-weight: bold;
  color: var(--text-anchor);
  text-decoration: none;
  border-bottom: 3px solid;
}

code {
  background-color: var(--text-code-background);
  border-radius: 5px;
  color: var(--text-code-color);
  padding: 0.15rem 0.6rem;
  font-family: $font-family-1;
  font-size: 0.8rem;
  margin-left: 5px;
  margin-right: 5px;
}

b {
  font-weight: 700;
}

.two-columns {
  grid-template-columns: auto auto;
}

.three-columns {
  grid-template-columns: auto auto auto;
}

.custom-button {
  background-color: var(--custom-button-bg-color);
  cursor: pointer;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 15px;
  border: 1px solid var(--table-border-color);
  font-size: 12px;
  transition: all 0.2s ease-in-out;

  span {
    color: var(--custom-button-text-color);
    margin-right: 5px;
  }

  fa-icon {
    color: var(--custom-button-text-color);
  }

  &:hover {
    background-color: var(--custom-button-bg-color-hover);

    span, fa-icon {
      color: var(--custom-button-text-color-hover);
    }
  }
}

.markdown {
  display: block;
  max-width: 100%;
  padding-bottom: 15px;

  p {
    font-weight: 100;
    line-height: 25px;
    max-width: 500px;
    margin-bottom: 15px;

    img {
      width: 100%;
      margin: 15px 0;
    }

    a {
      color: #647cff;
      font-weight: bold;
    }
  }

  h2 {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 15px;
    max-width: 500px;
  }
}

